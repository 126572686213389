import React from "react";
import {navbarCSS} from "../assets/navbar";
export const BCMD = () => {
    // eslint-disable-next-line react/style-prop-object

    return(
        <html>
        <head>
            <style>{navbarCSS()}
            </style>
            <script async
                    src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3297884501563958"
                    crossOrigin="anonymous"></script>
            <script async
                    src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3297884501563958"
                    crossOrigin="anonymous"></script>
            <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
            <meta charSet="utf-8"/>
            <meta name="keywords" content="BCMD Rework​"/>
            <meta name="description" content=""/>
            <title>Page 1</title>
            <link rel="stylesheet" href={require("./assets/nicepage.css")} media="screen"/>
            <link rel="stylesheet" href={require("./assets/bcmd.css")} media="screen"/>
            <meta name="generator" content="Nicepage 6.3.1, nicepage.com"/>
            <link id="u-theme-google-font" rel="stylesheet"
                  href="https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i|Open+Sans:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i"/>
            <meta name="theme-color" content="#478ac9"/>
            <meta property="og:title" content="BCMD"/>
            <meta property="og:type" content="website"/>
            <meta data-intl-tel-input-cdn-path="intlTelInput/"/>
        </head>
        <body data-path-to-root="./" data-include-products="false" className="u-body u-xl-mode" data-lang="en">
        <ul>
            <li><a href="/">Home</a></li>
            <li><a href="/projects">Projects</a></li>
            <li><a href="about-me">About Me</a></li>
        </ul>
        <header className="u-clearfix u-container-align-center u-header u-header" id="sec-9f24">
            <div className="u-clearfix u-sheet u-sheet-1">
                <h1 className="u-align-center u-text u-text-default u-text-1">BCMD Rework </h1>
            </div>
        </header>
        <section className="u-clearfix u-section-1" id="sec-cb0a">
            <div className="u-clearfix u-sheet u-sheet-1">
                <h3 className="u-align-center u-text u-text-default u-text-1">What is it? </h3>
                <p className="u-align-center u-text u-text-default u-text-2">Very well made plugin for minecraft that
                    optimizes players performance.<br/>And also adds a lot of Administration commands!
                </p>
                <a href="https://modrinth.com/plugin/better-commands/versions"
                   className="u-border-2 u-border-grey-75 u-btn u-btn-round u-button-style u-gradient u-none u-radius u-text-body-alt-color u-btn-1"
                   target="_blank" rel="noreferrer">Download Latest Release </a>
                <a href="https://dev.vitekform.x10.mx/buildsys"
                   className="u-border-2 u-border-grey-75 u-btn u-btn-round u-button-style u-gradient u-none u-radius u-text-body-alt-color u-btn-2"
                   target="_blank" rel="noreferrer">Download Latest Dev Build </a>
            </div>
        </section>


        <footer className="u-align-center u-clearfix u-footer u-grey-80 u-footer" id="sec-9e07">
            <div className="u-clearfix u-sheet u-sheet-1">
                <p className="u-small-text u-text u-text-variant u-text-1">Powered by React and vitekform's servers </p>
            </div>
        </footer>
        </body>
        </html>
    )
}