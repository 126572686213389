export const navbarCSS = () => {
    const  navbarstylecss = 'ul {\n' +
        '  list-style-type: none;\n' +
        '  margin: 0;\n' +
        '  padding: 0;\n' +
        '  overflow: hidden;\n' +
        '  background-color: #333;\n' +
        '}\n' +
        '\n' +
        'li {\n' +
        '  float: left;\n' +
        '}\n' +
        '\n' +
        'li a {\n' +
        '  display: block;\n' +
        '  color: white;\n' +
        '  text-align: center;\n' +
        '  padding: 14px 16px;\n' +
        '  text-decoration: none;\n' +
        '}\n' +
        '\n' +
        '/* Change the link color to #111 (black) on hover */\n' +
        'li a:hover {\n' +
        '  background-color: #111;\n' +
        '}'
    return (navbarstylecss);
}