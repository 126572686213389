import React from "react";
import {navbarCSS} from "./assets/navbar";
import {getAge, getWorking} from "./tools/tools";

export const AboutMe = () => {
    return (
        <html>
        <head>
            <link rel="stylesheet" href="./assets/global.css"/>
            <style>{navbarCSS()}
            </style>
            <title>About Me</title>
            <script async
                    src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3297884501563958"
                    crossOrigin="anonymous"></script>
            <script async
                    src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3297884501563958"
                    crossOrigin="anonymous"></script>
        </head>
        <body>
        <ul>
            <li><a href="/">Home</a></li>
            <li><a href="/projects">Projects</a></li>
            <li><a href="about-me">About Me</a></li>
        </ul>
        <h1>About Me</h1>
        <p>So first I think that I have to start with who the heck am I. So I am currently {getAge()} years old kid that studies programming and programs for fun. I am studying at Gymnázium Dašická 1083 Pardubice, Czech Republic. I mostly work in Java but I also use C#, C++, HTML, JavaScript and PHP. I am currently working on {getWorking()}.</p>
        </body>
        </html>
    )
}