import './App.css';
import React from "react";
import Cookies from 'universal-cookie';
import { Route, Routes } from "react-router-dom";
import { HomePage} from "./pages/home-page";
import { NotFoundPage} from "./pages/404";
import { Projects} from "./pages/projects";
import { BCMD } from "./pages/projects/bcmd";
import { TIMV } from "./pages/projects/timv";
import { AboutMe } from "./pages/about-me";
import {AreCookiesSet} from "./pages/assets/data";
import {Policy} from "./pages/policy";
import {DOA} from "./pages/projects/doa";
function App() {
    const cookies = new Cookies();
    if (AreCookiesSet === "false"){
        cookies.set("lang", "en_us");
    }
    return (
        <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/projects" element={<Projects />} />
                <Route path="/projects/bcmd" element={<BCMD />} />
                <Route path="/projects/timv" element={<TIMV />} />
                <Route path="/projects/doa" element={<DOA />} />
                <Route path="/about-me" element={<AboutMe />} />
                <Route path="/privacy" element={<Policy />} />
                <Route path="*" element={<NotFoundPage />} />
            </Routes>
  );
}
export default App;