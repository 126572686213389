import React from "react";
import {navbarCSS} from "./assets/navbar";
export const NotFoundPage = () => {
       return (
            <html>
            <head>
                <style>{navbarCSS()}
                </style>
                <title>Chyba 404</title>
                <link rel="stylesheet" href="./assets/global.css"/>
            </head>
            <body>
            <ul>
                <li><a href="/">Home</a></li>
                <li><a href="/projects">Projects</a></li>
                <li><a href="about-me">About Me</a></li>
            </ul>
            <img
                src="https://www.searchenginejournal.com/wp-content/uploads/2020/08/404-pages-sej-5f3ee7ff4966b-1520x800.webp"></img>
            </body>
            </html>
        );
};