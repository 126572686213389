
export const getAge = () => {
  let now = new Date();
  let born = new Date(2010, 10, 11, 0, 0, 0)
  let years = now.getFullYear() - born.getFullYear();
  if (now.getDate() <= 10 && now.getMonth() <= 9){
      years--;
  }
  return years;
}

export const getWorking = () => {
    return ("Dungeons Of Arcania (DOA) and Trouble in Mineville (TIMV)");
}