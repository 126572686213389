import react from "react";
import React from "react";
import {navbarCSS} from "../assets/navbar";

export const TIMV = () => {
    return(
        <html>
        <head>
            <title>TIMV</title>
            <link rel="stylesheet" href="../assets/global.css"/>
            <style>{navbarCSS()}
            </style>
            <script async
                    src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3297884501563958"
                    crossOrigin="anonymous"></script>
            <script async
                    src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3297884501563958"
                    crossOrigin="anonymous"></script>
        </head>
        <body>
        <ul>
            <li><a href="/">Home</a></li>
            <li><a href="/projects">Projects</a></li>
            <li><a href="about-me">About Me</a></li>
        </ul>
        <h1>This is project is currently not available! Please check later.</h1>
        </body>
        </html>
    )
}