import Cookies from "universal-cookie";

export const GetLanguage = () => {
    const cookies = new Cookies();
    return(cookies.get("lang"));
}

export const AreCookiesSet = () => {
    const cookies = new Cookies();
    let allCookies = cookies.getAll();
    let allCookiesArray = Object.values(allCookies);
    if (allCookiesArray.indexOf("lang") > -1){
        return "true";
    }
    else {
        return "false";
    }
}