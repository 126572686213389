import React from "react";
import {navbarCSS} from "./assets/navbar";
export const Projects = () => {
    return (<html>
    <head>
        <style>{navbarCSS()}
        </style>
        <title>Projects</title>
        <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css"/>
        <link rel="stylesheet" href="./assets/global.css"/>
        <script async
                src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3297884501563958"
                crossOrigin="anonymous"></script>
        <script async
                src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3297884501563958"
                crossOrigin="anonymous"></script>
    </head>
    <body>
    <ul>
        <li><a href="/">Home</a></li>
        <li><a href="#">Projects</a></li>
        <li><a href="about-me">About Me</a></li>
    </ul>
    <div className="w3-container w3-black">
        <h1>Projects</h1>
    </div>
    <div className="w3-panel w3-yellow">
        <h3>Warning!</h3>
        <p>Site us currently under construction so some informations may not be accurate.</p>
    </div>
    <a href="/projects/bcmd"><img src={require("./assets/bcmd_logo.jpg")} alt="BCMD Logo" height="360"
                                  width="360"></img></a>
    <a href="/projects/timv"><img src={require("./assets/timv_logo.jpg")} alt="TIMV Logo" height="360"
                                  width="360"></img></a>
    <a href="/projects/doa"><img src={require("./assets/doa/color_no_background.png")} alt="DOA Logo" height="360" width="360"></img></a>
    </body>
    </html>);

};