import React from "react";
import {navbarCSS} from "../assets/navbar";

export const DOA = () => {
  return (
      <html>
      <head>
          <style>{navbarCSS()}
          </style>
          <ul>
              <li><a href="/">Home</a></li>
              <li><a href="/projects">Projects</a></li>
              <li><a href="about-me">About Me</a></li>
          </ul>
      </head>

      </html>
  );
}